<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${warehouse.name}` }} -
        {{ $t("COMMON.STOCK_MOVEMENTS") }}
      </span>
    </div>

    <stock-movement-list-table :filterWarehouse="warehouse.id" />
  </div>
</template>
<script>
import StockMovementListTable from "../../StockMovementManagement/partials/StockMovementListTable.vue";

export default {
  name: "warehouse-view-stock-movements",

  components: { StockMovementListTable },

  props: ["warehouse"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
