<template>
  <el-select
    v-model="suppliersModel"
    @change="suppliersChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.SUPPLIERS')"
    :filterable="true"
    :multiple="true"
    :disabled="disabled"
    remote
    :remote-method="getSuppliers"
    :loading="loading"
  >
    <el-option
      v-for="(supplier, key) in suppliersList"
      :key="key"
      :value="supplier.id"
      :label="`${supplier.company_name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { difference } from "lodash";
import { Option, Select } from "element-ui";

export default {
  name: "suppliers-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    suppliers: {
      type: Array,
      default: () => [],
      description: "Suppliers",
    },
    organization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterActive: {
      type: Boolean,
      default: true,
      description: "",
    },
  },

  data() {
    return {
      suppliersModel: [...this.suppliers].map((item) => item.id),
      suppliersList: {},
    };
  },

  setup() {},

  created() {
    this.getSuppliers(null, this.suppliers);
  },

  methods: {
    async getSuppliers(query = null, suppliers = null) {
      try {
        this.loading = true;
        let params = {
          sort: "company_name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(suppliers ? { ids: suppliers.map((item) => item.id) } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.organization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.organization,
            },
          };
        }
        if (this.filterActive) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              active: 1,
            },
          };
        }

        await this.$store.dispatch("suppliers/list", params);
        const suppliersArr = await this.$store.getters["suppliers/list"];
        this.suppliersList = {};
        suppliersArr.forEach((supplier) => {
          this.suppliersList[supplier.id] = supplier;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async suppliersChanged(suppliers) {
      this.$emit(
        "suppliersChanged",
        [...suppliers].map((item) => ({ type: "suppliers", id: item }))
      );
    },
  },

  watch: {
    suppliers(suppliers) {
      if (suppliers?.length) {
        const suppliersData = suppliers.map((item) => item.id);
        if (difference(suppliersData, this.suppliersModel).length !== 0) {
          this.suppliersModel = suppliersData;
          this.getSuppliers(null, suppliers);
        }
      } else {
        this.suppliersModel = [];
      }
    },
    organization(organization) {
      this.getSuppliers();
    },
  },
};
</script>
