<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        :style="`${warehouse.is_model ? 'background-color: #E1E1E1E1;' : ''}`"
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-8">
              <h3 class="mb-0">
                {{ warehouse.name }}
                <base-button
                  v-if="$currentUserCan($permissions.PERM_EDIT_WAREHOUSES)"
                  type="primary"
                  icon
                  size="sm"
                  @click="editWarehouse()"
                  :title="$t('COMMON.EDIT')"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_DELETE_WAREHOUSES) &&
                    !warehouse.locked
                  "
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteWarehouse()"
                  :title="$t('COMMON.DELETE')"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="
                    () => {
                      printModalOpened = true;
                    }
                  "
                  v-if="!warehouse.is_model"
                  :title="$t('COMMON.EXPORT_EXCEL')"
                >
                  <span class="btn-inner--icon">
                    <i class="fa fa-file-excel"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_CREATE_STOCK_MOVEMENTS) &&
                    !warehouse.is_model
                  "
                  type="primary"
                  icon
                  size="sm"
                  @click="addCorrectStockMovement()"
                  :title="$t('COMMON.CORRECT_STOCK')"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-clipboard-check"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan(
                      $permissions.PERM_CREATE_STOCK_MOVEMENTS_FROM_EXISTING_QUANTITY
                    ) && !warehouse.is_model
                  "
                  class="btn btn-warning"
                  type="warning"
                  icon
                  @click="addCorrectStockMovementFromExistingQuantity()"
                  :title="$t('COMMON.CORRECT_STOCK_FROM_QUANTITY')"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-clipboard-check"></i>
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button @click="goBack()" type="button" class="btn btn-sm btn-primary">
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>

              <notification-subscription
                :objectType="'warehouses'"
                :objectId="warehouse.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
            </div>
          </div>
        </template>
        <div
          :style="`${warehouse.is_model ? 'background-color: #E1E1E1E1;' : ''}`"
          v-if="warehouse.id != null"
          :key="renderKey"
        >
          <div class="col-12 justify-content-center justify-content-sm-between flex-wrap">
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              :value="viewSupplier != null ? 'warehouse-products' : 'global'"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <warehouse-view-global :warehouse="warehouse" @warehouseUpdated="get" />
                </tab-pane>

                <tab-pane title="warehouse-products" id="3" v-if="warehouse.is_model">
                  <span slot="title">
                    <i class="fas fa-warehouse" />
                    {{ $t("COMMON.WAREHOUSES_CONFIGURED") }}
                  </span>
                  <warehouse-view-warehouse-configured
                    :warehouse="warehouse"
                    @warehouseUpdated="get"
                  />
                </tab-pane>

                <tab-pane title="warehouse-products" id="4">
                  <span slot="title">
                    <i class="fa fa-cubes" />
                    {{ $t("COMMON.PRODUCTS") }}
                  </span>
                  <warehouse-view-warehouse-products
                    :viewSupplier="viewSupplier"
                    :warehouse="warehouse"
                  />
                </tab-pane>

                <tab-pane title="warehouse-vqlue" id="5">
                  <span slot="title">
                    <i class="fa fa-money-check" />
                    {{ $t("WAREHOUSES.VALUE") }}
                  </span>
                  <warehouse-view-value
                    :warehouse="warehouse"
                    @onOpenSupplier="viewSupplierProducts"
                  />
                </tab-pane>

                <tab-pane title="warehouse-vqlue" id="6">
                  <span slot="title">
                    <i class="fa fa-truck" />
                    {{ $t("COMMON.STOCK_MOVEMENTS") }}
                  </span>
                  <warehouse-view-stock-movements :warehouse="warehouse" />
                </tab-pane>

                <tab-pane
                  title="files"
                  id="998"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
                >
                  <span slot="title">
                    <i class="ni ni-folder-17" />
                    {{ $t("COMMON.FILES") }}
                  </span>
                  <list-file-component :object="warehouse" />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <warehouse-view-logs :warehouse="warehouse" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>

    <modal :show.sync="printModalOpened" modal-classes="modal-secondary" size="xl">
      <div>
        <h2>
          {{ $t("WAREHOUSES.SELECT_SUPPLIERS") }}
        </h2>
        <div>
          <base-input
            :label="`${$t('COMMON.PRODUCTS')}`"
            :placeholder="$t('COMMON.PRODUCTS')"
          >
            <suppliers-selector
              :suppliers="[]"
              :filterable="true"
              :showAll="false"
              :organization="warehouse.organization.id"
              @suppliersChanged="
                (suppliers) => {
                  selectedSuppliers = suppliers.map((item) => item.id);
                }
              "
            />
          </base-input>
          <base-input
            :label="`${$t('COMMON.CATEGORIES')}`"
            :placeholder="$t('COMMON.CATEGORIES')"
          >
            <categories-selector
              :categories="[]"
              :filterable="true"
              :showAll="false"
              :organization="warehouse.organization.id"
              @categoriesChanged="
                (categories) => {
                  selectedCategories = categories.map((item) => item.id);
                }
              "
            />
          </base-input>

          <div class="my-4">
            <base-button
              type="button"
              class="btn btn-sm btn-primary"
              native-type="submit"
              @click="print()"
            >
              {{ $t("WAREHOUSES.PRINT") }}
            </base-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import { Tabs, TabPane } from "@/components";
import { TYPE_CORRECT } from "@/constants/stockMovements";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import defaultWarehouse from "./defaultWarehouse";
import WarehouseViewGlobal from "./partials/WarehouseViewGlobal.vue";
import WarehouseViewLogs from "./partials/WarehouseViewLogs.vue";
import WarehouseViewWarehouseProducts from "./partials/WarehouseViewWarehouseProducts.vue";
import WarehouseViewWarehouseConfigured from "./partials/WarehouseViewWarehouseConfigured";
import SuppliersSelector from "@/components/SuppliersSelector.vue";
import CategoriesSelector from "@/components/CategoriesSelector.vue";
import WarehouseViewValue from "./partials/WarehouseViewValue.vue";
import WarehouseViewStockMovements from "./partials/WarehouseViewStockMovements.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    WarehouseViewGlobal,
    WarehouseViewLogs,
    ListFileComponent,
    NotificationSubscription,
    WarehouseViewWarehouseProducts,
    WarehouseViewWarehouseConfigured,
    SuppliersSelector,
    WarehouseViewValue,
    CategoriesSelector,
    WarehouseViewStockMovements,
  },

  mixins: [],

  data() {
    return {
      warehouse: cloneDeep(defaultWarehouse),
      printModalOpened: false,
      selectedSuppliers: [],
      selectedCategories: [],
      viewSupplier: null,
      renderKey: 0,
    };
  },

  computed: {},

  watch: {
    "$route.params.id": function () {
      this.warehouse = cloneDeep(defaultWarehouse);
      this.get();
    },
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("warehouses/get", id);
        this.warehouse = this.$store.getters["warehouses/warehouse"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewSupplierProducts(supplierId) {
      this.viewSupplier = supplierId;
      this.renderKey++;
    },

    async editWarehouse(row) {
      this.$router.push({
        name: "Edit Warehouse",
        params: { id: this.warehouse.id },
      });
    },

    async deleteWarehouse() {
      const confirmation = await swal.fire({
        title: this.$t("WAREHOUSES.DELETE_THIS_WAREHOUSE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("warehouses/destroy", this.warehouse.id);
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("WAREHOUSES.WAREHOUSE_DELETED"),
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    goBack() {
      this.$router.push({ name: "List Warehouses" });
    },

    async addCorrectStockMovement() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        let stockMovementData = {
          type: "stock-movements",
          movement_type: TYPE_CORRECT,
          excerpt: "",
          relationshipNames: ["organization", "sourceWarehouse"],
          organization: this.warehouse.organization,
          sourceWarehouse: this.warehouse,
          existing: 0,
        };
        stockMovementData = this.$fillUserOrganizationData(stockMovementData);

        await this.$store.dispatch("stockMovements/add", stockMovementData);
        const stockMovement = await this.$store.getters["stockMovements/stockMovement"];

        this.$router.push({
          name: "View StockMovement",
          params: { id: stockMovement.id },
        });
        swal.close();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        swal.close();
      }
    },

    async addCorrectStockMovementFromExistingQuantity() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        let stockMovementData = {
          type: "stock-movements",
          movement_type: TYPE_CORRECT,
          excerpt: "",
          relationshipNames: ["organization", "sourceWarehouse"],
          organization: this.warehouse.organization,
          sourceWarehouse: this.warehouse,
          existing: 1,
        };
        stockMovementData = this.$fillUserOrganizationData(stockMovementData);

        await this.$store.dispatch("stockMovements/add", stockMovementData);
        const stockMovement = await this.$store.getters["stockMovements/stockMovement"];

        this.$router.push({
          name: "View StockMovement",
          params: { id: stockMovement.id },
        });
        swal.close();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        swal.close();
      }
    },

    async print() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const response = await this.$store.dispatch("warehouses/print", {
          id: this.warehouse.id,
          filters: {
            suppliers: this.selectedSuppliers,
            categories: this.selectedCategories,
          },
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `warehouse-${this.warehouse.id}.xlsx`);
        document.body.appendChild(link);
        link.click();

        this.selectedSuppliers = [];
        this.printModalOpened = false;
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        swal.close();
      }
    },
  },
};
</script>
